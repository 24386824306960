import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSpinner,
} from "mdb-react-ui-kit";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";
import toast,{Toaster} from 'react-hot-toast';

export default function Certificate() {
  const [basicModal, setBasicModal] = useState(false);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [vcode, setVcode] = useState("");
  const [submit, setSubmit] = useState(false);
  const [submitverify, setSubmitVerify] = useState(false);
  const [data, setData] = useState([]);

  const toggleShow = () => setBasicModal(!basicModal);

  async function handleVerify() {
    if(email){
      setSubmit(true);
      await fetch(
        `https://api.tierslimited.com/verifyemail?email=${email}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          if (data.message == "emailsent") {
            setSubmit(false);
            setCode(data.code);
            setEmail(data.email);
            setBasicModal(true);
          } else if (data.message == "invalid") {
            setSubmit(false);
            toast.error('Email not found');
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    else{
      toast.error('Email required');
    }
  }

  async function handleVerification() {
    setSubmitVerify(true);
    await fetch(
      `https://api.tierslimited.com/verify?email=${email}&incode=${vcode}&orcode=${code}`,
      {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        if (data.message == "verified") {
          setSubmitVerify(false);
          setBasicModal(false);
          Cookies.set("sesh_email", data.email, { expires: 1 });
          window.location.href = `/my-certificate`;
        } else if (data.message == "incorrect") {
          setSubmitVerify(false);
          setCode(data.code);
          setEmail(data.email);
          toast.error('Verification code is incorrect');
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handleVcode = (event) => {
    setVcode(event.target.value);
  };

  function downloadImage(url) {
    const imgURL = url;
    const fileName = "your_image_filename.jpg";

    const link = document.createElement("a");
    link.href = imgURL;
    link.download = fileName;

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  return (
    <div>
      <Navbar />
      <h4 style={{ marginTop: "40px", color: "#1d3768" }}>
        Get your Certificates here 
      </h4>
      <center>
        <div className="certifcate-input">
          <MDBInput
            label="Your Registered Email"
            id="formControlLg"
            type="email"
            size="lg"
            required
            style={{ marginTop: "10px" }}
            value={email}
            onChange={handleEmail}
          />
          <span id="error"></span>
          {submit ? (
            <MDBBtn style={{ marginTop: "20px", backgroundColor: "#1d3768" }} block>
              <MDBSpinner color="info"></MDBSpinner>
            </MDBBtn>
          ) : (
            <MDBBtn
              style={{ marginTop: "20px", backgroundColor: "#1d3768" }}
              block
              onClick={handleVerify}
            >
              Proceed
            </MDBBtn>
          )}
        </div>
      </center>

      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="6" className="mb-4 mb-lg-0">
            {data.map((item, index) => (
              <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                <MDBRow className="g-0">
                  <MDBCol md="12">
                    <MDBCardBody className="p-4">
                      <MDBTypography tag="h6">Information</MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Name</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.name}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Email</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.email}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Contact</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.contact}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Dated</MDBTypography>
                          <MDBCardText className="text-muted">
                            {new Date(item.dated).toLocaleString()}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Certificate</MDBTypography>
                          <MDBCardText className="text-muted">
                            <a
                              href="#"
                              download
                              onClick={() => {
                                downloadImage(
                                  `https://api.tierslimited.com/uploads/${item.certificate}`
                                );
                              }}
                            >
                              Download
                            </a>
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">
                            Internship Letter
                          </MDBTypography>
                          <MDBCardText className="text-muted">
                            <a
                              href={`https://api.tierslimited.com/uploads/${item.letter}`}
                              target="_blank"
                            >
                              Download
                            </a>
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            ))}
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <MDBModal show={basicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Email Verification</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleShow}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <MDBInput
                label="Verification Code"
                id="formControlLg"
                type="text"
                size="lg"
                value={vcode}
                onChange={handleVcode}
              />
              <span id="code-error"></span>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleShow}>
                Close
              </MDBBtn>
              {submitverify ? (
                <MDBBtn onClick={handleVerification}>
                  <MDBSpinner color="info"></MDBSpinner>
                </MDBBtn>
              ) : (
                <MDBBtn onClick={handleVerification}>Verify</MDBBtn>
              )}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <Toaster />
    </div>
  );
}
