import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import { MDBInput, MDBBtn, MDBSpinner } from "mdb-react-ui-kit";

export default function Certificate() {
  const [data, setData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [cid, setCid] = useState("");

  async function handleVerify(event) {
    event.preventDefault();
    setSubmit(true);
    await fetch(`https://api.tierslimited.com/verifycert?cid=${cid}`, {
      method: "GET",
      headers: {
        "api-key": process.env.REACT_APP_API_KEY,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Request failed.");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data.message);
        if (data.message == "notverified") {
          setSubmit(false);
          document.getElementById("error").innerHTML =
            "Certificate is Not Verified";
          document.getElementById("error").style.display = "block";
          document.getElementById("error").style.color = "red";
        }
        if (data.message == "verified") {
          window.location.href = `/verified?cid=${data.cid}`;
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const handleCID = (event) => {
    setCid(event.target.value);
  };

  return (
    <div>
      <Navbar />
      <h4 style={{ marginTop: "40px", color: "#1d3768" }}>
        Certificate Verification
      </h4>
      <center>
        <form onSubmit={handleVerify}>
          <div className="certifcate-input">
            <MDBInput
              label="Ceritificate ID"
              id="cid"
              type="number"
              size="lg"
              required
              style={{ marginTop: "10px" }}
              value={cid}
              onChange={handleCID}
            />
            <span id="error"></span>
            {submit ? (
              <MDBBtn style={{ marginTop: "20px", backgroundColor: "#1d3768" }} block>
                <MDBSpinner color="info"></MDBSpinner>
              </MDBBtn>
            ) : (
              <MDBBtn style={{ marginTop: "20px", backgroundColor: "#1d3768" }} block>
                Proceed
              </MDBBtn>
            )}
          </div>
        </form>
      </center>
    </div>
  );
}
