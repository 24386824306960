import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

export default function Slider() {
  return (
    <MDBCarousel showControls fade>
      <MDBCarouselItem
        className="carousel w-100 d-block"
        itemId={1}
        src="./Assets/slider.jpg"
        alt="Team-Work"
        style={{ position: "relative" }}
      >
        <div className="carousel-overlay"></div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="carousel w-100 d-block"
        itemId={2}
        src="./Assets/slider1.jpg"
        alt="Team-Work"
        style={{ position: "relative" }}
      >
        <div className="carousel-overlay"></div>
      </MDBCarouselItem>
      <MDBCarouselItem
        className="carousel w-100 d-block"
        itemId={3}
        src="./Assets/slider2.jpg"
        alt="Team-Work"
        style={{ position: "relative" }}
      >
        <div className="carousel-overlay"></div>
      </MDBCarouselItem>
    </MDBCarousel>
  );
}
