import './App.css';
import { Switch,Route } from 'react-router-dom';
import Home from './Components/home';
import {useEffect} from 'react';
import Certificate from './Components/certificate';
import Verify from './Components/verify';
import Toper from './Components/toper';
import Viewcert from './Components/viewcert';
import ViewVerify from './Components/viewverify';

function App() {
  useEffect(()=>{
    document.body.style.overflowX="hidden";
  },[])
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route exact path="/certificates" component={Certificate}/>
        <Route exact path="/verification" component={Verify}/>
        <Route exact path="/top-performers" component={Toper}/>
        <Route exact path="/my-certificate" component={Viewcert}/>
        <Route exact path="/verified" component={ViewVerify}/>
      </Switch>
    </div>
  );
}

export default App;
