import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";

export default function ViewVerify() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function handleVerify() {
      const queryParams = new URLSearchParams(window.location.search);
      const cid = queryParams.get("cid");
      await fetch(
        `https://api.tierslimited.com/verifycid?cid=${cid}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    handleVerify();
  }, []);

  return (
    <div>
      <Navbar />
      {data.length != 0 ? (
        <h2 style={{ marginTop: "40px", color: "green" }}>
          CERTIFICATE IS VERIFIED
        </h2>
      ) : null}

      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="6" className="mb-4 mb-lg-0">
            {data.map((item, index) => (
              <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                <MDBRow className="g-0">
                  <MDBCol md="12">
                    <MDBCardBody className="p-4">
                      <MDBTypography tag="h6">Information</MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Name</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.name}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Email</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.email}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Dated</MDBTypography>
                          <MDBCardText className="text-muted">
                            {new Date(item.dated).toLocaleString()}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            ))}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
