import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import { useLocation } from 'react-router-dom';

export default function Toper() {
  const [data, setData] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('year');

  useEffect(() => {
    async function gettoper() {
      await fetch(`https://api.tierslimited.com/gettopers?year=${paramValue}`, {
        method: "GET",
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          setData(data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    gettoper();
  }, []);

  return (
    <div>
      <Navbar />
      <h4 style={{ marginTop: "40px", color: "#1d3768" }}>
        Top Performers {paramValue}
      </h4>
      <center>
        <MDBContainer className="py-5">
          <MDBRow className="text-center">
            {data.map((item, index) => (
              <MDBCol md="4" className="mb-5 mb-md-0">
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src={`https://api.tierslimited.com/uploads/${item.image}`}
                    className="rounded-circle shadow-1-strong"
                    width="150"
                    height="150"
                  />
                </div>
                <h5 className="mb-3">{item.name}</h5>
                <h6
                  className="text-primary mb-3"
                  style={{ marginTop: "-10px" }}
                >
                  {index==0?"Web Dev - MERN": index==1? "Web Dev - Django": "Mobile App Development"}
                </h6>
                <p className="px-xl-3" style={{ marginTop: "-10px" }}>
                  {item.email}
                </p>
                <p className="px-xl-3">
                  <MDBIcon fas icon="quote-left" className="pe-2" />
                  {item.message}
                </p>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </center>
    </div>
  );
}
