import React, { useState, useEffect } from "react";
import Navbar from "./navbar";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import Cookies from "js-cookie";

export default function Viewcert() {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function handleVerify() {
      const sesh = Cookies.get("sesh_email");
      console.log(sesh);
      await fetch(
        `https://api.tierslimited.com/mycert?email=${sesh}`,
        {
          method: "GET",
          headers: {
            "api-key": process.env.REACT_APP_API_KEY,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Request failed.");
          }
          return response.json();
        })
        .then((data) => {
          console.log(data.data);
          setData(data.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
    handleVerify();
  }, []);

  async function downloadImage(imageUrl) {
    const fileName = "your_image_filename.jpg"; // Replace with the desired filename for the downloaded image

    // Fetch the image as a Blob
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();

    // Create an object URL from the Blob
    const objectURL = URL.createObjectURL(imageBlob);

    // Create a temporary anchor element to initiate the download
    const link = document.createElement("a");
    link.href = objectURL;
    link.download = fileName;

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(objectURL);
    document.body.removeChild(link);
  }

  return (
    <div>
      <Navbar />
      <h4 style={{ marginTop: "40px", color: "#1d3768" }}>
        Download your certificate here
      </h4>

      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="6" className="mb-4 mb-lg-0">
            {data.map((item, index) => (
              <MDBCard className="mb-3" style={{ borderRadius: ".5rem" }}>
                <MDBRow className="g-0">
                  <MDBCol md="12">
                    <MDBCardBody className="p-4">
                      <MDBTypography tag="h6">Information</MDBTypography>
                      <hr className="mt-0 mb-4" />
                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Name</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.name}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Email</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.email}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Contact</MDBTypography>
                          <MDBCardText className="text-muted">
                            {item.contact}
                          </MDBCardText>
                        </MDBCol>
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Dated</MDBTypography>
                          <MDBCardText className="text-muted">
                            {new Date(item.dated).toLocaleString()}
                          </MDBCardText>
                        </MDBCol>
                      </MDBRow>

                      <MDBRow className="pt-1">
                        <MDBCol size="6" className="mb-3">
                          <MDBTypography tag="h6">Certificate</MDBTypography>
                          <MDBCardText className="text-muted">
                            <a
                              href={item.Id<71?`https://notch-cactus-vacuum.glitch.me/uploads/${item.certificate}`:`https://api.tierslimited.com/uploads/${item.certificate}`}
                              target="_blank"
                            >
                              View
                            </a>
                            <br />
                            <a
                              href="#"
                              download="certificate.png"
                              onClick={() => {
                                downloadImage(item.Id<71?`https://notch-cactus-vacuum.glitch.me/uploads/${item.certificate}`:
                                  `https://api.tierslimited.com/uploads/${item.certificate}`
                                );
                              }}
                            >
                              Download
                            </a>
                          </MDBCardText>
                        </MDBCol>
                        {item.Id<71?(
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">
                              Internship Letter
                            </MDBTypography>
                            <MDBCardText className="text-muted">
                              <a
                                href={item.Id<71? `https://notch-cactus-vacuum.glitch.me/uploads/${item.letter}` : `https://api.tierslimited.com/uploads/${item.letter}`}
                                target="_blank"
                              >
                                View
                              </a>
                              <br />
                              <a
                                href={item.Id<71? `https://notch-cactus-vacuum.glitch.me/uploads/${item.letter}` :`https://api.tierslimited.com/uploads/${item.letter}`}
                                target="_blank"
                              >
                                Download
                              </a>
                            </MDBCardText>
                          </MDBCol>
                        ):(
                          ""
                        )}
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            ))}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
